import axios from "axios";
import { getAuth } from "firebase/auth";

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const axiosInstance = axios.create({
  baseURL: baseURL + "/api"
})

axiosInstance.interceptors.request.use(
  async (config) => {
    if (config.method === 'post') {
      const token = localStorage.getItem('token');
      const currentTime = Date.now();

      if (token && currentTime < getTokenExpiration(token)) {
        config.headers['Authorization'] = `Bearer ${token}`;
      } else {
        // トークンが存在しないか、有効期限切れの場合は、新しいトークンを取得
        const newToken = await getAuth().currentUser.getIdToken(true);
        localStorage.setItem('token', newToken)
        config.headers['Authorization'] = `Bearer ${newToken}`
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Base64URL 形式の文字列を標準 Base64 形式に置き換えてからデコードするユーティリティ関数
 */
function decodeBase64Url(base64Url) {
  // Base64URLの '-' と '_' をそれぞれ '+' と '/' に置き換え
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  // Base64は4の倍数長である必要があるため、パディングを追加
  const padding = 4 - (base64.length % 4);
  if (padding !== 4) {
    base64 += '='.repeat(padding);
  }

  // atob() でBase64デコード → 文字列
  return atob(base64);
}

/**
 * トークンの有効期限 (exp) を取得
 * @param {string} token - JWT(アクセストークン)
 * @returns {number|null} 有効期限(ミリ秒)またはnull
 */
export function getTokenExpiration(token) {
  try {
    // 1. トークンを . で区切って3パーツかチェック
    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error('Invalid JWT format: expected 3 parts');
    }

    // 2. ペイロード部分(Base64URL)を取り出す
    const payloadBase64Url = parts[1];

    // 3. Base64URL -> Base64に変換して文字列をデコード
    const payloadJson = decodeBase64Url(payloadBase64Url);

    // 4. JSONパース
    const decodedPayload = JSON.parse(payloadJson);

    // 5. expフィールドをチェック
    if (typeof decodedPayload.exp !== 'number') {
      throw new Error('No valid exp field in token payload');
    }

    // expは秒単位なのでミリ秒に変換
    return decodedPayload.exp * 1000;
  } catch (error) {
    console.error('Failed to decode token expiration:', error);
    // 解析できなかった場合は null を返す (もしくはエラーを再throwしてもよい)
    return null;
  }
}
